


























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import AccountInfo from "@/views/Navigation/AccountInfo.vue";
import AccountSearch from "@/views/Navigation/AccountSearch.vue";
import AuthService from "@/services/auth/auth.service";
const auth = new AuthService();
@Component({
  components: {
    AccountInfo,
    AccountSearch
  }
})
export default class SidebarNavigationItems extends Vue {
  navigation: {}[] = [
    {
      exact: true,
      href: "/",
      title: "Dashboard",
      icon: "desktop",
      show: this.showItem
    },
    {
      exact: true,
      href: "/individuals",
      title: "Individuals",
      icon: "user-circle",
      show: true
    },
    {
      exact: true,
      href: "/organisations",
      title: "Organisations",
      icon: "building",
      show: true
    },
    // {
    //   exact: true,
    //   href: "/clients",
    //   title: "Clients",
    //   icon: "lock",
    //   show: true
    // },
    {
      exact: true,
      href: "/applications",
      title: "Applications",
      show: this.showItem,
      icon: "scroll"
    },
    {
      exact: true,
      href: "/forms",
      title: "Forms",
      icon: "file-alt",
      show: true
    },
    {
      href: "/configuration",
      title: "Configuration",
      icon: "cog",
      show: true,
      child: [
        // {
        //   href: "/configuration/accountTypes",
        //   title: "Account Types"
        // },
        {
          href: "/configuration/communications",
          title: "Communications"
        }
        // {
        //   href: "/configuration/communicationtypes",
        //   title: "Communication Type"
        // },
        // {
        //   href: "/configuration/countries",
        //   title: "Countries"
        // },
        // {
        //   href: "/configuration/packages",
        //   title: "Subscription Packages"
        // },
        // {
        //   href: "/configuration/entitlements",
        //   title: "Subscription Entitlements"
        // },
        // {
        //   href: "/configuration/questions",
        //   title: "Questions"
        // },
        // {
        //   href: "/configuration/questiontypes",
        //   title: "Question Types"
        // },
        // {
        //   href: "/configuration/thirdParties",
        //   title: "Third Parties"
        // }
      ]
    }
  ];
  isOpen: boolean = false;
  isToggleId: number | null = null;

  toggleSubMenu(toggleId: number): void {
    this.isToggleId = toggleId;
    this.isOpen = !this.isOpen;
  }

  get showItem() {
    return this.$store.getters.getRole;
  }

  get linkListener(): any {
    return Object.assign({}, this.$listeners, {
      click: (event: any) => {
        this.$emit("click", event.target.value);
      }
    });
  }

  close() {
    this.$store.commit("TOGGLE_MENU", false);
  }

  mounted() {
    const listener = (e: { target: any }) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        return;
      }
      this.isOpen = false;
    };
    document.addEventListener("click", listener);
    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("click", listener);
    });
  }
  logout() {
    auth.logout();
  }
  render() {
    if (!this.$slots.default || this.$slots.default.length === 0) {
      return "";
    } else if (this.$slots.default.length === 1) {
      return this.$slots.default[0];
    }
  }
}
